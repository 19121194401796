import { Moment } from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box, Stack } from '@mui/system';
import "./TransactionDateTimeForm.css";
import moment from 'moment';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { formatDateForApiCall } from '../../../../utils/DataManipulation';

export type TransactionDateTimeFormProp = {
    startDate: Moment | null,
    endDate: Moment | null,
    setStartDate: React.Dispatch<React.SetStateAction<Moment | null>>,
    setEndDate: React.Dispatch<React.SetStateAction<Moment | null>>,
    initialInterval: number,
    getTransactionDetailsBetweenDates: (startDate: string, endDate: string) => void,
    loadTransactionDetailsByInterval: (interval: number) => void,
}

const TransactionDateTimeForm: React.FC<TransactionDateTimeFormProp> = ({ 
    startDate, 
    endDate, 
    setStartDate, 
    setEndDate, 
    initialInterval, 
    getTransactionDetailsBetweenDates,
    loadTransactionDetailsByInterval 
}) => {
    const [isApplyDisabled, setIsApplyDisabled] = useState(false);
    const [displayError, setDisplayError] = useState<string[]>([]);

    const errorMessage: string[] = ['Start Date cannot be greater than End Date', 'Please Select any 24 hours Time Range'];

    useEffect(() => { validateDates(startDate, endDate) }, [startDate, endDate]);

    const resetDates = () => {
        setStartDate(moment().subtract(initialInterval, 'hours'));
        setEndDate(moment());
        loadTransactionDetailsByInterval(initialInterval);
    }

    const validateDates = (startDate: Moment | null, endDate: Moment | null) => {
        const formattedStartDate = startDate;
        const formattedEndDate = endDate;

        // Reset errors and apply button state
        setIsApplyDisabled(false);
        setDisplayError([]);

        // Start Date cannot be greater than End Date
        if (formattedEndDate.isBefore(formattedStartDate)) {
            setIsApplyDisabled(true);
            setDisplayError((prevErrors) => [...prevErrors, errorMessage[0]]);
        }

        // User cannot select beyond 24 hours of selection
        if (formattedEndDate.diff(formattedStartDate, 'hours') > 24) {
            setIsApplyDisabled(true);
            setDisplayError((prevErrors) => [...prevErrors, errorMessage[1]]);
        }
    };

    function fetchTransactionDetailsBetweenDates(){
        const startDateString = formatDateForApiCall(startDate);
        const endDateString = formatDateForApiCall(endDate)
        getTransactionDetailsBetweenDates(startDateString,endDateString);
    }

    return (
        <Stack spacing={1}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack direction="row" spacing={2}>
                    <DateTimePicker<Moment>
                        label="Start Date time"
                        className='transaction-date-time-picker'
                        value={startDate}
                        minDateTime={moment().subtract(6, 'days').subtract(1, 'minute')}
                        maxDateTime={moment()}
                        onChange={(newValue) => setStartDate(newValue)}
                        timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                    />
                    <DateTimePicker<Moment>
                        label="End Date time"
                        className='transaction-date-time-picker'
                        value={endDate}
                        minDateTime={moment().subtract(6, 'days')}
                        maxDateTime={startDate ? moment(startDate).add(24, 'hours').add(1, 'minute') : null}
                        onChange={(newValue) => setEndDate(newValue)}
                        timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                    />
                </Stack>
            </LocalizationProvider>
            <Box className="transaction-visibility-action-buttons">
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" color="success" disabled={isApplyDisabled} onClick={fetchTransactionDetailsBetweenDates}>
                        Apply
                    </Button>
                    <Button variant="outlined" color="error" onClick={resetDates}>
                        Reset
                    </Button>
                </Stack>
            </Box>
            <Box>
                {displayError.map((value, index) => <span key={index} className="transaction-date-time-error-display"> {value} </span>)}
            </Box>
        </Stack>
    );

}

export default TransactionDateTimeForm;