// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-card-container {
    border: 2px solid #008000 ;
    height: 100%;
}
.store-card-avatar{
    background-color: white !important;
}
.store-card-icon{
    color: #008000;
}
.store-card-content .store-card-typography{
    color: #008000 ;
    font-weight: bold ;
    font-family: 'Google Sans', Arial, Helvetica, sans-serif;
}
.store-card-title .store-card-typography{
    color: #008000;
    font-weight: bold;
    font-family: 'Google Sans', Arial, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/kpi-dashboard-tabs/transaction-visibility/store-card/StoreCard.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,YAAY;AAChB;AACA;IACI,kCAAkC;AACtC;AACA;IACI,cAAc;AAClB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,wDAAwD;AAC5D;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,6CAA6C;AACjD","sourcesContent":[".store-card-container {\n    border: 2px solid #008000 ;\n    height: 100%;\n}\n.store-card-avatar{\n    background-color: white !important;\n}\n.store-card-icon{\n    color: #008000;\n}\n.store-card-content .store-card-typography{\n    color: #008000 ;\n    font-weight: bold ;\n    font-family: 'Google Sans', Arial, Helvetica, sans-serif;\n}\n.store-card-title .store-card-typography{\n    color: #008000;\n    font-weight: bold;\n    font-family: 'Google Sans', Arial, sans-serif;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
