// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-selector-container {
    width: 40%;
    font-weight: 600;
    color: green;
}
`, "",{"version":3,"sources":["webpack://./src/components/kpi-dashboard-tabs/price-comparison/range-selector/range-selector.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".range-selector-container {\n    width: 40%;\n    font-weight: 600;\n    color: green;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
