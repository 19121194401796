// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mrt-expand-table-container{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 0.5px solid black;
    display: flex;
    align-items: center;
}

.mrt-expand-td-header {
    font-weight: 500;
}

.mrt-expand-td {
    padding: 4px 12px 4px 12px;
    font-size: 0.8rem;
    font-family: Google Sans, Arial, Helvetica, sans-serif;
}

.mrt-table-heading{
    color: green;
    font-weight: 600;
    font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/kpi-dashboard-tabs/transaction-visibility/transaction-table-minimal.tsx/TransactionTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,oCAAoC;IACpC,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,iBAAiB;IACjB,sDAAsD;AAC1D;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".mrt-expand-table-container{\n    width: 100%;\n    background-color: rgba(0, 0, 0, 0.1);\n    border: 0.5px solid black;\n    display: flex;\n    align-items: center;\n}\n\n.mrt-expand-td-header {\n    font-weight: 500;\n}\n\n.mrt-expand-td {\n    padding: 4px 12px 4px 12px;\n    font-size: 0.8rem;\n    font-family: Google Sans, Arial, Helvetica, sans-serif;\n}\n\n.mrt-table-heading{\n    color: green;\n    font-weight: 600;\n    font-size: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
