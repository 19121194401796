// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-button-group {
  display: flex;
  flex-wrap: wrap;
}

.MuiToggleButtonGroup-root {
  justify-content: flex-start;
}

.MuiToggleButton-root {
  flex-grow: 1;
  text-align: center;
}

.no-capitalize {
  text-transform: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/kpi-dashboard-tabs/transaction-visibility/time-interval-selector/TimeIntervalSelector.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".toggle-button-group {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.MuiToggleButtonGroup-root {\n  justify-content: flex-start;\n}\n\n.MuiToggleButton-root {\n  flex-grow: 1;\n  text-align: center;\n}\n\n.no-capitalize {\n  text-transform: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
