/* eslint-disable no-undef */
import React, { useEffect } from "react";

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  children?: React.ReactNode;
  zoom: number;
  center: google.maps.LatLng | google.maps.LatLngLiteral;
  mapBounds: any;
}

const USA_CANADA_BOUNDS = {
  north: 75.45,
  south: 5.9,
  west: -179.91,
  east: -38.02,
};

const Map: React.FC<MapProps> = ({
  children,
  zoom,
  center,
  style,
  mapBounds,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          fullscreenControl: false,
          restriction: {
            latLngBounds: USA_CANADA_BOUNDS,
            strictBounds: true,
          },
          mapTypeControl: false,
          streetViewControl: false,
        })
      );
    }
  }, [ref, map]);

  useEffect(() => {
    if (mapBounds && map) {
      map.setCenter(mapBounds.getCenter());
      map.fitBounds(mapBounds);
      map.setZoom(map.getZoom() - 1);
      if (map.getZoom() > 15) {
        map.setZoom(15);
      }
    }
  }, [mapBounds, map]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map;
