import React, { useState, useEffect, useMemo } from "react";
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";
import TableauIframe from "../../common/tableau/TableauIframe";

type OpisHistoryTableauProps = {
  storeId: string
  isMaximize: boolean
}

const OpisHistoryTableau: React.FC<OpisHistoryTableauProps> = ({ storeId,isMaximize}) => {
  const url = "https://prod-useast-a.online.tableau.com/t/711/views/OPISHistoryDTCVsPerformance/OPISHistory?&SEI=" + storeId + "&:showVizHome=no&:embed=true&:alerts=no&:subscriptions=no";

  const iframe = {
    id: "opisHistoryTableau",
    url: url,
    isMaximize
  }

  return (<TableauIframe iframe={iframe} />);
}
export default OpisHistoryTableau;
