import { ToggleButtonGroup, Tooltip, ToggleButton } from "@mui/material";
import { useEffect, useState } from "react";
import "./TimeIntervalSelector.css";
import { Moment } from "moment";
import moment from "moment";

export type TimeIntervalSelectorProps = {
    interval: number,
    setInterval: React.Dispatch<React.SetStateAction<number>>,
    startDate: Moment | null, 
    setStartDate: React.Dispatch<React.SetStateAction<Moment | null>>,
    endDate: Moment | null, 
    setEndDate: React.Dispatch<React.SetStateAction<Moment | null>>,
    loadTransactionDetailsByInterval: (interval: number) => void
}

const TimeIntervalSelector: React.FC<TimeIntervalSelectorProps> = ({
    interval,
    setInterval,
    startDate, 
    setStartDate,
    endDate, 
    setEndDate,
    loadTransactionDetailsByInterval
}) => {
    const timeIntervals = [
        { value: 2, label: '2hr', title: 'Click to View Last 2 Hours of Data' },
        { value: 4, label: '4hr', title: 'Click to View Last 4 Hours of Data' },
        { value: 8, label: '8hr', title: 'Click to View Last 8 Hours of Data' },
        { value: 12, label: '12hr', title: 'Click to View Last 12 Hours of Data' },
        { value: 24, label: '24hr', title: 'Click to View Last 24 Hours of Data' },
    ]; 

    useEffect(() => {
        updateIntervalState()
    }, [startDate,endDate]);

    function updateIntervalState(){
        const calcInterval = calculateInterval(startDate, endDate);
        setInterval(calcInterval);
    }

    function calculateInterval(startDate:Moment | null , endDate: Moment | null){
        const hourDifference = endDate.diff(startDate, 'hours');
        const isMatchingTimeInterval = timeIntervals.find(interval => interval.value === hourDifference);
        if(isMatchingTimeInterval){
            return hourDifference ;
        }
        return 0;
    }

    function syncDatesWithTimeInterval(newInterval){
        setStartDate(moment().subtract(newInterval, 'hours'));
        setEndDate(moment());
    };

    const handleIntervalChange = (event: React.MouseEvent<HTMLElement>, newInterval: number | null) => {
        if(newInterval === null) newInterval = interval;
        syncDatesWithTimeInterval(newInterval);
        setInterval(newInterval);
        loadTransactionDetailsByInterval(newInterval);
    };

    return (
        <ToggleButtonGroup
            color="success"
            value={interval}
            exclusive
            onChange={handleIntervalChange}
            className="toggle-button-group"
        >
            {timeIntervals.map((interval) => (
                <Tooltip key={interval.value} title={interval.title} arrow>
                    <ToggleButton value={interval.value}>
                        {interval.label}
                    </ToggleButton>
                </Tooltip>
            ))}
        </ToggleButtonGroup>
    );
};

export default TimeIntervalSelector;