import { Box, Stack } from "@mui/system";
import { TransactionTable } from "../transaction-table-minimal.tsx/TransactionTable";
import "./TransactionVisibility.css";
import { Divider } from "@mui/material";
import StoreCard, { StoreCardProps } from "../store-card/StoreCard";
import OverallStatisticsCard, { OverallStatisticsProps } from "../overall-statistics/OverallStatisticsCard";
import { useEffect, useMemo, useState } from "react";
import TransactionVisibilityActionCenter, { TransactionVisibilityActionCenterProps } from "../transaction-action-center/TransactionActionCenter";
import TransactionVisibilitySkeleton from "../../../common/skeleton-load/TransactionVisibilitySkeleton";
import { TransactionVisibilityProps } from "../../../../types/TransactionVisibilityProps";
import { StoreMetaInfo } from "../../../../types/StoreMetaInfo";
import { StoreTransactionDetails } from "../../../../types/StoreTransactionDetails";
import { TransactionInfo } from "../../../../types/TransactionInfo";
import { processAndParseRawTransactionData, processOverallPrices as processOverallTotals, processProductOptions, processTableData } from "./TransactionVisibilityService";
import { OverallTotals } from "../../../../types/OverallTotals";
import moment, { Moment } from "moment";

const TransactionVisibility: React.FC<TransactionVisibilityProps> = (props: TransactionVisibilityProps) => {

    const initialInterval = 8;
    const [storeInfo, setStoreInfo] = useState<StoreMetaInfo>();
    const [iconList, setIconList] = useState([]);
    const [iconsFetched, setIconsFetched] = useState(false);
    const [logo, setLogo] = useState<string>();
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [productOption, setProductOptions] = useState<string[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [totalSalesAmount, setTotalSalesAmount] = useState<number>(0);
    const [totalSalesQuantity, setTotalSalesQuantity] = useState<number>(0);
    const [transactionInfoResponse, setTransactionInfoResponse] = useState<TransactionInfo[]>();
    const [transactiondataBucketForTable, setTransactiondataBucketForTable] = useState<TransactionInfo[]>();
    const [startDate, setStartDate] = useState<Moment | null>(moment().subtract(initialInterval, 'hours'));
    const [endDate, setEndDate] = useState<Moment | null>(moment());
    const [interval, setInterval] = useState<number | null>(initialInterval);
    const [error, setError] = useState<string[]>([]);


    useMemo(() => {
        let isMounted = true;
        fetchIconList(isMounted);
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (!iconsFetched) return;
        loadStoreDetails(props.storeId);
        loadTransactionDetailsByInterval(initialInterval);
    }, [props.storeId, iconsFetched]);

    useEffect(() => updateTransactionVisbility(), [selectedProducts]);

    function fetchIconList(isMounted: boolean) {
        const subscription = props.transactionVisibilityFunctions.fetchListOfIcons().subscribe((iconList) => {
            if (!isMounted) return;
            setIconList(iconList);
            setIconsFetched(true);
        });
        return () => subscription.unsubscribe();
    }

    function identifyLogo(iconId: string) {
        const logo = iconList?.filter((icon) => icon.iconid === iconId)[0]?.filename || '';
        setLogo(logo);
    }

    function loadStoreDetails(storeId: string) {
        setIsDataLoading(true);
        const subscription = props.transactionVisibilityFunctions.getStoreDetails(storeId)
            .subscribe({
                next: (response: StoreMetaInfo) => {
                    setStoreInfo(response);
                    identifyLogo(response?.locationDetails?.icon);
                    setIsDataLoading(false);
                    setError([]);
                },
                error: (err) => {
                    console.error("Error occured while loading store data", err);
                    setError(prev => [...prev, "Please retry, Error occured while loading store data " +  err.name]);
                    setIsDataLoading(false);
                }
            });
        return () => subscription.unsubscribe();
    }

    function loadTransactionDetailsByInterval(interval: number) {
        setIsDataLoading(true);
        const subscription =
            props.transactionVisibilityFunctions.getTransactionDetailsByInterval(props.storeId, interval)
                .subscribe({
                    next: (response: StoreTransactionDetails[]) => {
                        loadTransactionDetails(response);
                        setIsDataLoading(false);
                        setError([]);
                    },
                    error: (err) => {
                        console.error("Error occured while loading transaction data", err);
                        setError(prev => [...prev, "Please retry, Error occured while loading transaction data " +  err.name]);
                        setIsDataLoading(false);
                    }
                });
        return () => subscription.unsubscribe();
    }

    function getTransactionDetailsBetweenDates(startDate: string, endDate: string) {
        setIsDataLoading(true);
        const subscription =
            props.transactionVisibilityFunctions.getTransactionDetailsBetweenDates(props.storeId, startDate, endDate)
                .subscribe({
                    next: (response: StoreTransactionDetails[]) => {
                        loadTransactionDetails(response);
                        setIsDataLoading(false);
                        setError([]);
                    },
                    error: (err) => {
                        console.error("Error occured while loading transaction data", err);
                        setError(prev => [...prev, "Please retry, Error occured while loading transaction data " + err.name]);
                        setIsDataLoading(false);
                    }
                });
        return () => subscription.unsubscribe();
    }

    function loadTransactionDetails(response) {
        const transactionInfoList: TransactionInfo[] = processAndParseRawTransactionData(response);
        const products = processProductOptions(transactionInfoList);
        const overallTotals = processOverallTotals(products, transactionInfoList);
        setupTransactionVibilityState(products, transactionInfoList, overallTotals);
    }

    function setupTransactionVibilityState(products: string[], transactionInfoList: TransactionInfo[], overallTotals: OverallTotals) {
        setProductOptions(products);
        setSelectedProducts(products);
        setTransactionInfoResponse(transactionInfoList);
        setTotalSalesAmount(overallTotals.totalSalesAmount);
        setTotalSalesQuantity(overallTotals.totalSalesQuantity);
        setTransactiondataBucketForTable(transactionInfoList);
    }

    function updateTransactionVisbility() {
        if (!transactionInfoResponse) return;
        const overallTotals = processOverallTotals(selectedProducts, transactionInfoResponse);
        const tableData = processTableData(selectedProducts, transactionInfoResponse);
        setTotalSalesAmount(overallTotals.totalSalesAmount);
        setTotalSalesQuantity(overallTotals.totalSalesQuantity);
        setTransactiondataBucketForTable(tableData);
    }

    //Child Props
    const overallStatisticsProps: OverallStatisticsProps = { totalSalesAmount, totalSalesQuantity, productOption, selectedProducts, setSelectedProducts };
    const transactionVisibilityActionProps: TransactionVisibilityActionCenterProps = { startDate, setStartDate, endDate, setEndDate, interval, setInterval, initialInterval, selectedProducts, loadTransactionDetailsByInterval, getTransactionDetailsBetweenDates };
    const storeCardProps: StoreCardProps = { logo, storeInfo };

    return (
        <>
            <Box className="transaction-visibility-header"> Transaction Visibility - {props.storeId} </Box>
            {
                error.length > 0 ?
                    <Box className="transaction-visibility-error">
                        {error.map((message, index) => (
                            <span key={index}>{message}</span>
                        ))}
                    </Box>
                    : <></>
            }
            {isDataLoading ? <TransactionVisibilitySkeleton /> :
                <Stack direction='column' spacing={1} className="transaction-visibility-container">
                    <Stack direction='row' spacing={2} className="transaction-visibility-top">
                        <Box className="transaction-visibility-time-range">
                            <TransactionVisibilityActionCenter {...transactionVisibilityActionProps} />
                        </Box>
                        <Stack direction='row' spacing={1} className="transaction-visibility-meta-data-container">
                            <Box className="transaction-visibility-statistics">
                                <OverallStatisticsCard  {...overallStatisticsProps} />
                            </Box>
                            <Divider />
                            <Box className="transaction-visibility-store-card">
                                <StoreCard {...storeCardProps} />
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack direction='row' spacing={1} className="transaction-visibility-bottom">
                        <Box className="transaction-visibility-table-container">
                            <TransactionTable data={transactiondataBucketForTable} />
                        </Box>
                        <Box className="transaction-visibility-tbd">  </Box>
                    </Stack>
                </Stack>
            }
        </>
    );
}

export default TransactionVisibility;