// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --iframe-max-height: 82dvh;
}

.iframe-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
}

.no-border {
  border: none;
}

.mid-height {
  height: calc(var(--iframe-max-height) * 0.66);
}

.full-height {
  height: var(--iframe-max-height);
}
`, "",{"version":3,"sources":["webpack://./src/components/common/tableau/Tableau.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[":root {\n  --iframe-max-height: 82dvh;\n}\n\n.iframe-container {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  margin-bottom: 1rem;\n}\n\n.no-border {\n  border: none;\n}\n\n.mid-height {\n  height: calc(var(--iframe-max-height) * 0.66);\n}\n\n.full-height {\n  height: var(--iframe-max-height);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
