// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kpi-dashboard-disabled{
    width: 100%;
    height: calc(100vh - 43.1vh);
}
.disabled-wrapper {
    border-image: repeating-linear-gradient(45deg, #f1f3f4, #f1f3f5 20px, #fff 20px, #fff 40px) fill 1;
}

.kpi-dashboard-disabled .disabled-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: grey;
    min-height: 200px;
    overflow: hidden;
}

.frown-large-icon-flex-center {
    font-size: 36px;
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/common/disabled-screen/DisabledScreen.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,4BAA4B;AAChC;AACA;IACI,kGAAkG;AACtG;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".kpi-dashboard-disabled{\n    width: 100%;\n    height: calc(100vh - 43.1vh);\n}\n.disabled-wrapper {\n    border-image: repeating-linear-gradient(45deg, #f1f3f4, #f1f3f5 20px, #fff 20px, #fff 40px) fill 1;\n}\n\n.kpi-dashboard-disabled .disabled-content {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    font-size: 14px;\n    color: grey;\n    min-height: 200px;\n    overflow: hidden;\n}\n\n.frown-large-icon-flex-center {\n    font-size: 36px;\n    margin-left: 2px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
