import { Box } from "@mui/system";
import { RowSelectionType } from "../../../types/RowSelectionType";
import "./DisabledScreen.css";

interface DisabledScreenProps {
    rowSelectionType: RowSelectionType
}


const DisabledScreen: React.FC<DisabledScreenProps> = ({ rowSelectionType }) => {


    const message = rowSelectionType === 'Multiple' ? "Multiple stores are selected, please select single store" : "No stores selected, Please select a store";

    return (
        <Box className="kpi-dashboard-disabled disabled-wrapper kpi-dashboard-content">
            <div className="disabled-content">
                <div className="frown-large-icon-flex-center">&#x2639;</div>
                {message}
            </div>
        </Box>
    );

}

export default DisabledScreen;