import { Wrapper } from "@googlemaps/react-wrapper";
import PriceComparison from "./price-comparison";
import { StoreProximityProps } from "../../../types/StoreProximityProps";

const ProximityWrapper: React.FC<StoreProximityProps> = (proximityProps) => {
    let wrapper = <></>;
    if (proximityProps && proximityProps.storeData) {
        try {
            wrapper = (
                <Wrapper apiKey={"AIzaSyD1TE3AO2uxwIkTvfvvXezofBNFZ98zau4"}>
                    <PriceComparison  {...proximityProps} />
                </Wrapper>
            );
            
        } catch (error) {
            console.error("Error occured in store proximity", error);
            wrapper = (<div> Error... </div>);
        }
    } else {
        wrapper = (<div> No Data for store proximity </div>);
    }

    return wrapper;
}

export default ProximityWrapper;