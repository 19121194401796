import React, { useState, useEffect, useMemo } from "react";
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";
import TableauIframe from "../../common/tableau/TableauIframe";

type VolumePerformanceTableauProps = {
  storeId: string,
  isMaximize: boolean
}

const VolumePerformanceTableau: React.FC<VolumePerformanceTableauProps> = ({storeId,isMaximize}) => {
  const url = "https://prod-useast-a.online.tableau.com/t/711/views/FuelKPIAnalysisDashboard/Overview?&Store%20no=" + storeId + "&:showVizHome=no&:embed=true&:alerts=no&:subscriptions=no" ;

  const iframe = {
    id: "VolumePerformanceTableau",
    url: url,
    isMaximize,
  }

  return (<TableauIframe iframe={iframe} />);
}
export default VolumePerformanceTableau;
