import { Box, Skeleton, Stack } from "@mui/material";
import "./StoreProximitySkeleton.css";


const TransactionVisibilitySkeleton = () => {

    const animation = 'wave';
    const halfWidth = '50%';
    const bonesInHumanBody = 206;

    return (
        <Stack direction='row' spacing={2} className="skeleton-container">
            <Box className="skeleton-generic-box">
                <Skeleton animation={animation} variant="text" />
                <Stack direction='row' spacing={1} className="skeleton-button-container">
                    <Skeleton
                        animation={animation}
                        variant="rectangular"
                        width={halfWidth}
                        className="left-rectangular-skeleton"
                    />
                    <Skeleton
                        animation={animation}
                        variant="rectangular"
                        width={halfWidth}
                        className="left-rectangular-skeleton"
                    />
                </Stack>
                <br />
                <Stack direction='row' spacing={1}>
                    <Skeleton
                        animation={animation}
                        variant="rounded"
                        width={80}
                        height={30}
                    />
                    <Skeleton
                        animation={animation}
                        variant="rounded"
                        width={80}
                        height={30}
                    />
                </Stack>
                <Stack direction='row' spacing={1} className="skeleton-button-container">
                    <Skeleton animation={animation} className="skeleton-box-chonky" />
                    <Skeleton animation={animation} className="skeleton-box-chonky" />
                </Stack>
                <Skeleton
                    className="table-rectangular-skeleton"
                    animation={animation}
                    variant="rectangular"
                    height={bonesInHumanBody}
                />
            </Box>
            <Box className="skeleton-generic-box">
                <Stack direction='row' spacing={1} className="skeleton-button-container">
                    <Skeleton
                        className="table-rectangular-skeleton"
                        animation={animation}
                        variant="rectangular"
                        width={halfWidth}
                        height={bonesInHumanBody}
                    />
                    <Skeleton
                        className="table-rectangular-skeleton"
                        animation={animation}
                        variant="rectangular"
                        width={halfWidth}
                        height={bonesInHumanBody}
                    />
                </Stack>
            </Box>
        </Stack>
    );
}

export default TransactionVisibilitySkeleton;