import { Card, CardHeader, CardContent, Typography, CardActions, Autocomplete, Checkbox, TextField, Chip } from "@mui/material";
import "./OverallStatisticsCard.css";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Dispatch, SetStateAction } from "react";


export type OverallStatisticsProps = {
    totalSalesAmount: number,
    totalSalesQuantity: number,
    productOption: string[],
    selectedProducts: string[],
    setSelectedProducts: Dispatch<SetStateAction<string[]>>
}

const OverallStatisticsCard: React.FC<OverallStatisticsProps> = ({ 
    totalSalesAmount, 
    totalSalesQuantity, 
    productOption,
    selectedProducts, 
    setSelectedProducts
}) => {

    return (
        <Card className="overall-statistics-container">
            <CardHeader
                title="Overall Statistics"
                className="overall-statistics-title"
            />
            <CardContent className="overall-statistics-content">
                <Typography variant="body2" className="overall-statistics-typography">
                    Total Sale Amount($): {totalSalesAmount?.toFixed(2)}
                </Typography>
                <Typography variant="body2" className="overall-statistics-typography">
                    Total Sale Quantity(Gal): {totalSalesQuantity?.toFixed(2)}
                </Typography>
                <Typography variant="body2" className="overall-statistics-typography">
                    Average Actual Sale Price($): {totalSalesAmount / totalSalesQuantity ? (totalSalesAmount / totalSalesQuantity).toFixed(2) : "0.00"}
                </Typography>
            </CardContent>
            <CardActions>
                <Autocomplete
                    multiple
                    limitTags={2}
                    id="transaction-visibility-autocomplete"
                    options={productOption}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value={selectedProducts}
                    onChange={(event, value) => {
                        setSelectedProducts(value);
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                color="success" 
                                variant="filled"
                                label={option}
                                size="small"
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderOption={(props, option, { selected }) => {
                        return (
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    color="success"
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {String(option)}
                            </li>
                        );
                    }}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Products"
                            placeholder="Select Products"
                            className="text-field-green-outline"
                        />
                    )}
                />
            </CardActions>
        </Card>
    );
}

export default OverallStatisticsCard;