// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overall-statistics-container{
    height: 100%;
}
.overall-statistics-title {
    color: #008000;
}

.overall-statistics-content .overall-statistics-typography {
    color: grey;
    font-weight: bold;
    font-family: 'Google Sans', Arial, Helvetica, sans-serif;
}


.text-field-green-outline .MuiOutlinedInput-root {
    border-color: green;
}

.text-field-green-outline .MuiOutlinedInput-root:hover fieldset {
    border-color: green;
}

.text-field-green-outline .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: green;
}

.text-field-green-outline label.Mui-focused {
    color: green;
}
`, "",{"version":3,"sources":["webpack://./src/components/kpi-dashboard-tabs/transaction-visibility/overall-statistics/OverallStatisticsCard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,wDAAwD;AAC5D;;;AAGA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".overall-statistics-container{\n    height: 100%;\n}\n.overall-statistics-title {\n    color: #008000;\n}\n\n.overall-statistics-content .overall-statistics-typography {\n    color: grey;\n    font-weight: bold;\n    font-family: 'Google Sans', Arial, Helvetica, sans-serif;\n}\n\n\n.text-field-green-outline .MuiOutlinedInput-root {\n    border-color: green;\n}\n\n.text-field-green-outline .MuiOutlinedInput-root:hover fieldset {\n    border-color: green;\n}\n\n.text-field-green-outline .MuiOutlinedInput-root.Mui-focused fieldset {\n    border-color: green;\n}\n\n.text-field-green-outline label.Mui-focused {\n    color: green;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
