import { Card, CardHeader, Avatar, CardContent, Typography } from "@mui/material";
import { assetUrl } from "../../../../utils/asset-url";
import StoreIcon from '@mui/icons-material/Store';
import "./StoreCard.css";
import { StoreMetaInfo } from "../../../../types/StoreMetaInfo";

export type StoreCardProps = {
    logo: string,
    storeInfo: StoreMetaInfo,
}

const notAvailable = 'N/A';

const StoreCard: React.FC<StoreCardProps> = ({ storeInfo, logo }) => {

    const storeIconPath = assetUrl(`/images/logos/${logo}`);

    return (
        <Card className="store-card-container">
            <CardHeader
                avatar={
                    <Avatar variant='rounded'
                        src={storeIconPath} alt="Store Icon"
                        className="store-card-avatar"
                    >
                        <StoreIcon fontSize="large" className="store-card-icon" />
                    </Avatar>
                }
                title={<StoreTitle storeInfo={storeInfo} />}
                subheader={<b>{storeInfo?.locationDetails?.name || notAvailable}</b>}
            />

            <CardContent className="store-card-content">
                <Typography variant="body2" className="store-card-typography">
                    {storeInfo?.address?.streetAddress || notAvailable}
                </Typography>
                <Typography variant="body2" className="store-card-typography">
                    {storeInfo?.address?.city || notAvailable}, {storeInfo?.address?.state || notAvailable}, {storeInfo?.address?.zipCode || notAvailable}
                </Typography>
                <Typography variant="body2" className="store-card-typography">
                    {storeInfo?.address?.country || notAvailable}
                </Typography>
            </CardContent>
        </Card>
    );
}

const StoreTitle = ({ storeInfo }) => {

    return (
        <div className="store-card-title">
            <Typography variant="body1" className="store-card-typography">
                {storeInfo?.locationDetails?.storeId || notAvailable} - {storeInfo?.businessParameters?.alternateId}
            </Typography>
            <Typography variant="body1" className="store-card-typography">
                OPIS ID - {storeInfo?.locationDetails?.opisId || notAvailable}
            </Typography>
        </div>
    )
}

export default StoreCard;