// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-date-time-picker{
    width: 100%;
}

.transaction-date-time-error-display {
    color: red;
    font-weight: bold;
  }`, "",{"version":3,"sources":["webpack://./src/components/kpi-dashboard-tabs/transaction-visibility/date-time-picker/TransactionDateTimeForm.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;IACV,iBAAiB;EACnB","sourcesContent":[".transaction-date-time-picker{\n    width: 100%;\n}\n\n.transaction-date-time-error-display {\n    color: red;\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
