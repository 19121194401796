import { Moment } from 'moment';
import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';
import "./TransactionActionCenter.css";
import TimeIntervalSelector, { TimeIntervalSelectorProps } from '../time-interval-selector/TimeIntervalSelector';
import TransactionDateTimeForm, { TransactionDateTimeFormProp } from '../date-time-picker/TransactionDateTimeForm';

export type TransactionVisibilityActionCenterProps = {
    startDate: Moment | null, 
    setStartDate: React.Dispatch<React.SetStateAction<Moment | null>>,
    endDate: Moment | null, 
    setEndDate: React.Dispatch<React.SetStateAction<Moment | null>>,
    interval: number, 
    setInterval: React.Dispatch<React.SetStateAction<number>>,
    initialInterval: number,
    selectedProducts: string[],
    loadTransactionDetailsByInterval: (interval: number) => any,
    getTransactionDetailsBetweenDates: (startDate: string, endDate: string) => any
}

const TransactionVisibilityActionCenter: React.FC<TransactionVisibilityActionCenterProps> = ({ 
    startDate, 
    setStartDate,
    endDate, 
    setEndDate,
    interval, 
    setInterval, 
    initialInterval, 
    selectedProducts,
    loadTransactionDetailsByInterval, 
    getTransactionDetailsBetweenDates
}) => {
    const transactionDateTimeFormProps: TransactionDateTimeFormProp = {startDate,endDate,setStartDate,setEndDate,initialInterval,loadTransactionDetailsByInterval, getTransactionDetailsBetweenDates};
    const timeIntervalSelector: TimeIntervalSelectorProps = {interval, setInterval, startDate, setStartDate,endDate, setEndDate, loadTransactionDetailsByInterval};

    return (
        <Stack spacing={2}>
            <Box className="transaction-visibility-products">
                Products Available: {selectedProducts.length > 0 ? selectedProducts?.join(", "): "N/A"}
            </Box>
            <Box className="transaction-visibility-date-time-picker">
                <TransactionDateTimeForm {...transactionDateTimeFormProps} />
            </Box>
            <Stack direction="row" spacing={8} className="transaction-visibility-graphs">
                <Box className="transaction-visibility-heading">Transaction Graphs</Box>
                <Box className="transaction-visibility-interval-selector ">
                    <TimeIntervalSelector {...timeIntervalSelector} />
                </Box>
            </Stack>
        </Stack>
    );
}

export default TransactionVisibilityActionCenter;