import { Tabs, Tooltip, Tab } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import "./KpiTabs.css";

export interface KpiDashBoardTabsProps {
  isEnabled: boolean,
  label: string;
  icon: JSX.Element;
  component: JSX.Element;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export type kpiTabsProps = {
  listOfTabs: KpiDashBoardTabsProps[];
  requestedTab?: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="tabpanel"
      {...other}
    >
      {value === index && (
        <Box key={index} className="tabpanel-box"> 
          {children}
        </Box>
      )}
    </div>
  );
}

const KpiTabs: React.FC<kpiTabsProps> = ({ listOfTabs, requestedTab }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  useEffect(() => {requestedTab && setCurrentTabIndex(requestedTab)},[requestedTab]);

  useEffect(()=>{
    if(!listOfTabs[4].isEnabled && currentTabIndex === listOfTabs.length - 1){
      setCurrentTabIndex(0);
    }
  });

  return (
    <Box className="kpi-dashboard-tabs-container">
      <Tabs
        orientation="vertical"
        value={currentTabIndex}
        onChange={handleChange}
        aria-label="KPI Dashboard Tabs"
        className="kpi-dashboard-tabs"
      >
        {listOfTabs.map((tab, index) => (
          tab.isEnabled &&
          <Tooltip
            title={tab.label}
            placement="right"
            componentsProps={{ tooltip: { sx: { fontSize: '12px' } } }}
            key={index}
          >
            <Tab icon={tab.icon} className="kpi-dashboard-each-tab" />
          </Tooltip>
        ))}
      </Tabs>
      {listOfTabs.map((tab, index) => (
        tab.isEnabled &&
        <TabPanel value={currentTabIndex} index={index} key={index}>
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default KpiTabs;