// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-container{
    display: flex;
    width: 97%;
    padding: 16px;
    justify-content: space-evenly;
}
.skeleton-generic-box{
    width: 100%;
}
.left-rectangular-skeleton {
    height: 51px !important;
    border-radius: 5px;
}
.table-rectangular-skeleton{
    border-radius: 5px;
}
.skeleton-button-container{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    border-radius: 5px;
}
.skeleton-box-chonky{
    width: 100%;
    height: 54px !important;
    border-radius: 5px;
}
.longer{
    width: 150%
}
.right-rectangular-skeleton{
    height: 330px !important;
    border-radius: 5px;
}


`, "",{"version":3,"sources":["webpack://./src/components/common/skeleton-load/StoreProximitySkeleton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,aAAa;IACb,6BAA6B;AACjC;AACA;IACI,WAAW;AACf;AACA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,uBAAuB;IACvB,kBAAkB;AACtB;AACA;IACI;AACJ;AACA;IACI,wBAAwB;IACxB,kBAAkB;AACtB","sourcesContent":[".skeleton-container{\n    display: flex;\n    width: 97%;\n    padding: 16px;\n    justify-content: space-evenly;\n}\n.skeleton-generic-box{\n    width: 100%;\n}\n.left-rectangular-skeleton {\n    height: 51px !important;\n    border-radius: 5px;\n}\n.table-rectangular-skeleton{\n    border-radius: 5px;\n}\n.skeleton-button-container{\n    display: flex;\n    justify-content: space-evenly;\n    width: 100%;\n    border-radius: 5px;\n}\n.skeleton-box-chonky{\n    width: 100%;\n    height: 54px !important;\n    border-radius: 5px;\n}\n.longer{\n    width: 150%\n}\n.right-rectangular-skeleton{\n    height: 330px !important;\n    border-radius: 5px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
