import { OverallTotals } from "../../../../types/OverallTotals";
import { StoreTransactionDetails } from "../../../../types/StoreTransactionDetails";
import { TransactionInfo } from "../../../../types/TransactionInfo";
import { obtainFuelsTransaction, convertingTransactionDates } from "../../../../utils/DataManipulation";

export function processAndParseRawTransactionData(response: StoreTransactionDetails[]): TransactionInfo[] {
    const transactionInfoList: TransactionInfo[] = obtainFuelsTransaction(response);
    const parsedTransactionInfoList = convertingTransactionDates(transactionInfoList);
    return parsedTransactionInfoList;
}

export function processProductOptions(transactionInfoList: TransactionInfo[]): string[] {
    const productSet = new Set<string>();
    transactionInfoList.forEach((fuelTxn) => productSet.add(fuelTxn.fueldescription));
    return Array.from(productSet);
}

export function processTableData(selectedProducts: string[], transactionInfoList: TransactionInfo[]): TransactionInfo[] {
    const tableData = transactionInfoList.filter(data => selectedProducts.includes(data.fueldescription));
    return tableData;
}

export function processOverallPrices(selectedProducts: string[], transactionInfoList: TransactionInfo[]): OverallTotals {
    const totals: OverallTotals = transactionInfoList.reduce(
        (totals, fuelTxn) => {
            if (selectedProducts.includes(fuelTxn.fueldescription)) {
                totals.totalSalesAmount += fuelTxn.salesamount;
                totals.totalSalesQuantity += fuelTxn.salesquantity;
            }
            return totals;
        },
        { totalSalesAmount: 0, totalSalesQuantity: 0 }
    );

    return totals
}