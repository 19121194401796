import { Box, Skeleton, Stack, Typography } from "@mui/material";
import "./StoreProximitySkeleton.css";


const StoreProximitySkeleton = () => {

    const animation = 'wave';
    const bonesInHumanBody = 206;

    return (
            <Stack direction='row' spacing={2} className="skeleton-container">
                <Box className="skeleton-generic-box">
                    <Skeleton animation={animation} variant="text" className="skeleton-box-chonky" />
                    <Skeleton
                        animation={animation}
                        variant="rectangular"
                        className="left-rectangular-skeleton"
                    />
                    <Skeleton animation={animation} />
                    <Skeleton animation={animation} />
                    <Skeleton animation={animation} />
                    <br/>
                    <Skeleton
                        className="table-rectangular-skeleton"
                        animation={animation}
                        variant="rectangular"
                        height={bonesInHumanBody}
                    />
                </Box>
                <Box className="skeleton-generic-box">
                    <Typography variant="h1">
                        <Stack direction='row' spacing={1} className="skeleton-button-container">
                        <Skeleton animation={animation} className="skeleton-box-chonky longer" />
                        <Skeleton animation={animation} className="skeleton-box-chonky" />
                        <Skeleton animation={animation} className="skeleton-box-chonky" />
                        </Stack>
                    </Typography>
                    <Skeleton
                        animation={animation}
                        variant="rectangular"
                        className="right-rectangular-skeleton"
                    />
                </Box>
            </Stack>
    );

}

export default StoreProximitySkeleton;