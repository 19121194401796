import { TransactionVisibilityProps } from "../../../../types/TransactionVisibilityProps";
import TransactionVisibility from "./TransactionVisibility";

const TransactionVisbilityWrapper: React.FC<TransactionVisibilityProps> = (transactionVisbilityProps) => {
    let wrapper = <></>;
    if (transactionVisbilityProps && transactionVisbilityProps.storeId) {
        try {
            wrapper = (
                
                    <TransactionVisibility  {...transactionVisbilityProps} />
            );
            
        } catch (error) {
            console.error("Error occured in Transaction visbility", error);
            wrapper = (<div> Error... </div>);
        }
    } else {
        wrapper = (<div> No data to display for Transaction visbility </div>);
    }

    return wrapper;
}

export default TransactionVisbilityWrapper;