import { useMemo, useState } from 'react';
import {
  MRT_Table,
  MRT_TablePagination,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import "./TransactionTable.css";
import { Box } from '@mui/system';
import { TransactionInfo } from '../../../../types/TransactionInfo';

export type TransactionTableProps = {
  data: TransactionInfo[]
}

const transactionExpandMapping = {
  txnGUID: 'Txn GUID',
  createdDate: 'Created Date',
  transactionID: 'Transaction ID',
  pricingUID_7eP: 'PricingUID 7eP',
  offlineFlag: 'Offline Flag',
  suspendflag: 'Suspend Flag',
  a2istatus: 'A2I Status',
  vendormodelversion: 'Vendor Model Version',
  outsidesalesflag: 'Outside Sales Flag',
  fuelTxnID: 'Fuel Txn ID',
  tendertxnid: 'Tender Txn ID',
  transactionlinestatus: 'Transaction Line Status',
  tendercode: 'Tender Code',
  tendersubcode: 'Tender Subcode',
  accountid: 'Account ID',
  providerId: 'Provider ID',
  accountname: 'Account Name'
}

export const TransactionTable: React.FC<TransactionTableProps> = (props: TransactionTableProps) => {

  const data = props.data ? props.data : [];

  const columns = useMemo<MRT_ColumnDef<TransactionInfo>[]>(
    () => [
      { accessorKey: 'beginDateTime', header: 'Txn Date', maxSize: 20 },
      { accessorKey: 'fueldescription', header: 'Fuel Grade', maxSize: 20 },
      { accessorKey: 'actualsalespriceamount', header: 'Act. Sale Price', maxSize: 20 },
      { accessorKey: 'regularsalepriceamount', header: 'Reg. Sale Price', maxSize: 20 },
      { accessorKey: 'salesquantity', header: 'Sale Qty', maxSize: 20 },
      { accessorKey: 'salesamount', header: 'Sale Amt', maxSize: 20 },
    ],
    [],);
  const [expanded, setExpanded] = useState({});
  const handleRowClick = (row) => {
    const rowId = row.id;
    setExpanded((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const handleHeaderClick = () => {
    setExpanded(!expanded);
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableColumnActions: false,
    enableExpandAll: false,
    enableExpanding: false,
    enableStickyHeader: true,
    enableColumnFilters: false,
    enableSorting: false,
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
    }),
    muiTableProps: {
      sx: {
        border: '1px solid rgba(81, 81, 81, .5)',
        font: 8,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: '1px solid rgba(81, 81, 81, .5)',
        color: 'white',
        fontWeight: 'normal',
        background: 'green',
        paddingTop: 2,
        paddingBottom: 2,
        alignItems: 'center',
        justifyContent: 'center'
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: '1px solid rgba(81, 81, 81, .5)',
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [4, 12, 16],
      sx: {
        maxHeight: '10px !important',
        padding: '4px',
        '.MuiButtonBase-root': {
          background: 'red',
          borderRadius: '12px',
          padding: '6px 12px',
        },
      },
      showFirstButton: true,
      showLastButton: true,
    },
    muiTableHeadRowProps: ({ table }) => ({ onClick: () => handleHeaderClick(), }),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleRowClick(row),
      sx: { cursor: 'pointer' },
    }),
    state: {
      expanded,
    },
    initialState: {
      density: "compact",
      columnVisibility: { 'mrt-row-expand': false },
      pagination: { pageSize: 12, pageIndex: 0 }
    },
    muiExpandButtonProps: {
      sx: {
        display: 'none', // Hides the expand button
      },
    },
    renderDetailPanel: ({ row }) => (
      <Box className="mrt-expand-table-container">
        <table>
          {Object.entries(row.original).map(([key, value]) =>
          transactionExpandMapping[key] &&
            <tr key={key}>
              <td className="mrt-expand-td mrt-expand-td-header">{transactionExpandMapping[key]}</td>
              <td className="mrt-expand-td">{value}</td>
            </tr>
          )}
        </table>
      </Box>
    ),
  });

  //using MRT_Table instead of MaterialReactTable if we do not need any of the toolbar components or features
  return (
    <Box>
      <Box className="mrt-table-heading">List of Transaction </Box>
      <MRT_Table table={table} />
      <MRT_TablePagination table={table} />
    </Box>
  );
};

export default TransactionTableProps;
