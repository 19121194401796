import React, { useState, useEffect, useMemo } from "react";
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";
import TableauIframe from "../../common/tableau/TableauIframe";

type PerformanceKPITableauProps = {
  storeId: string
  isMaximize: boolean
}

const PerformanceKPITableau: React.FC<PerformanceKPITableauProps> = ({storeId,isMaximize}) => {
  const url = "https://prod-useast-a.online.tableau.com/t/711/views/PerformanceKPI/VisopsPOC?&Store%20no=" + storeId + "&:showVizHome=no&:embed=true&:alerts=no&:subscriptions=no" ;
  const iframe = {
    id: "PerformanceKPITableau",
    url: url,
    isMaximize
  }

  return (<TableauIframe iframe={iframe} />);
}
export default PerformanceKPITableau;
