import React, { useState, useEffect, useMemo } from "react";
import { Button, useMediaQuery } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CalculateIcon from "@mui/icons-material/Calculate";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import RangeSelector from "./range-selector/range-selector";
import PriceTable from "./price-table/price-table";
import "./price-comparison.css";
import Map from "./map/MapComponent";
import Marker from "./map/Marker";
import { assetUrl } from "../../../utils/asset-url";
import { StoreProximityProps } from "../../../types/StoreProximityProps";
import StoreProximitySkeleton from "../../common/skeleton-load/StoreProximitySkeleton";

const getSVGMarker = (color: string, scale: number, strokeWeight = 0) => {
  const svgMarker = {
    path: "M384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384z",
    fillColor: color,
    fillOpacity: 1,
    strokeWeight,
    strokeColor: "#004225",
    rotation: 0,
    scale,
    labelOrigin: new google.maps.Point(200, 200),
  };
  return svgMarker;
};

const getInfowindow = (importcode: string, description: string) => {
  let contentString = `<p>Site ID: ${importcode}</p>`;
  contentString += `<p>Description: ${description}</p>`;
  const infowindow = new google.maps.InfoWindow({
    content: contentString,
  });
  return infowindow;
};

const PriceComparison: React.FC<StoreProximityProps> = ({ storeProximityFunctions, storeData, isMaximize}) => {
  const [storeDataState, setStoreDataState] = useState(null);
  const [storesData, setStoresData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [radius, setRadius] = useState(1);
  const [calculateDisabled, setCalculateDisabled] = useState(true);
  const [priceData, setPriceData] = useState({});
  const [mapBounds, setMapBounds] = useState<any>();
  const [showLoader, setShowLoader] = useState(false);

  const isSmallScreen = useMediaQuery('(width:400px)');

  // const iconUrl = assetUrl("/images/logos/" + storeDataState?.brandname?.toLowerCase() + ".png" || storeDataState.logo);

  const otherLocationsMarkers = useMemo(() => {
    const otherLocations = storesData?.map((store) => {
      return {
        latLng: {
          lat: store?.location?.lat,
          lng: store?.location?.lon,
        },
        icon: getSVGMarker(
          store?.isCompetitor ? "#5956bc" : "#f67810",
          selected?.importcode == store?.importcode ? 0.1 : 0.06
        ),
        importcode: store?.importcode,
        description: store?.description ?? store?.importcode,
        infowindow: getInfowindow(
          store?.importcode,
          store?.description ?? store?.importcode
        ),
      };
    });
    return otherLocations || [];
  }, [storesData, selected]);

  const primaryLocationMarker = useMemo(() => {
    const primaryLocation = {
      latLng: storeDataState?.location,
      icon: getSVGMarker("#4CBB17", 0.1, 2),
      importcode: storeDataState?.importcode,
      description: storeDataState?.description ?? storeDataState?.importcode,
      infowindow: getInfowindow(
        storeDataState?.importcode,
        storeDataState?.description ?? storeDataState?.importcode
      ),
    };
    return primaryLocation;
  }, [storeDataState]);

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();
    primaryLocationMarker?.latLng &&
      bounds.extend(primaryLocationMarker.latLng);
    otherLocationsMarkers?.forEach(
      (location) => location.latLng && bounds.extend(location.latLng)
    );
    (primaryLocationMarker?.latLng || otherLocationsMarkers?.length) &&
      setMapBounds(bounds);
  }, [otherLocationsMarkers, primaryLocationMarker]);

  const selectForComparison = (data) => {
    setSelected(data);
  };

  useEffect(() => {
    if (storeData.importcode) {
      storeProximityFunctions
        .fetchLocationPrices(storeData.importcode)
        .subscribe((data: any) => {
          setPriceData(data);
          const x = {
            ...storeData,
            ...data,
            location: {
              lat: storeData.location?.lat,
              lng: storeData.location?.lon,
            },
          };
          setStoreDataState(x);
        });
      calculateButton(radius);
    }
  }, [storeData.importcode]);

  const calculateButton = (radiusVal?: number) => {
    setShowLoader(true);
    setCalculateDisabled(true);
    storeProximityFunctions
      .getPriceCompareData(
        {
          lat: storeData.location?.lat,
          lng: storeData.location?.lon,
        },
        (radiusVal || radius) + "mi"
      )
      .subscribe((data: any) => {
        const list = data.hits.hits.map((x) => {
          return { ...x.sort, ...x["_source"] };
        });

        const importCodes = list.map((x) => x.importcode);
        if (importCodes.length) {
          storeProximityFunctions
            .getPriceComparePrices(importCodes)
            .subscribe((data: any) => {
              setShowLoader(false);
              const mergedList = list.map((x) => {
                const y = data.find((obj) => obj.importcode === x.importcode);
                return {
                  ...x,
                  ...y,
                };
              });
              setStoresData(
                mergedList.filter(
                  (x) => x.importcode !== storeData.importcode
                )
              );
            });
        }
      });
  };

  const resetButton = () => {
    setRadius(1);
    setSelected(null);
    calculateButton(1);
  };


  const closeAllInfoWindows = () => {
    primaryLocationMarker?.infowindow?.close();
    otherLocationsMarkers.forEach((marker) => {
      marker?.infowindow?.close();
    });
  };

  return (

    <div
      className="price-comparison-wrapper"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="price-comparison-header-container">
        <div className="price-comparison-header">Store Proximity Analysis</div>
      </div>
      <Stack direction='row' spacing={2} className="price-comparison-container">
        {((storeDataState === null && storesData.length === 0) || showLoader) && (
          <StoreProximitySkeleton />
        )}
        {storesData && storesData.length > 0 && !showLoader && (
          <>
            <div className="price-comparison-store">
              <Stack direction="row" spacing={1} className="current-store">
                <div style={{ padding: '5px' }}>
                  <img className="icon-style" alt={"logo"}
                    src={assetUrl(`/images/logos/${priceData["icon"]}`)}
                  />
                </div>
                <Divider orientation="vertical" flexItem />
                <div style={{ padding: '5px' }}>
                  <b>StoreID: </b>
                  {priceData["importcode"]}
                </div>
                <Divider orientation="vertical" flexItem />
                <div style={{ padding: '5px' }}>
                  <b>Brand: </b>
                  {priceData["brand"] ? priceData["brand"] : "N/A"}
                </div>
                <Divider orientation="vertical" flexItem />
                <div style={{ padding: '5px' }}>
                  <b>OpisID: </b>
                  {priceData["opisId"] ? priceData["opisId"] : "N/A"}
                </div>
              </Stack>
              <div className="price-comparison-data-container">
                {storeDataState && (
                  <PriceTable
                    storesData={[storeDataState]}
                    isPagination={false}
                    selectedStore={selected}
                  />
                )}

                {storesData && storesData.length > 0 && !showLoader && (
                  <PriceTable
                    selectForComparison={selectForComparison}
                    storesData={storesData}
                    storeData={storeDataState}
                    selectedStore={selected}
                    isPagination={true}
                  />
                )}
              </div>
            </div>
            <div className="price-comparison-map-container">
              <Stack direction="row" spacing={2} className="price-comparison-map-tools">
                <RangeSelector
                  setRadius={setRadius}
                  radius={radius}
                  setCalculateDisabled={setCalculateDisabled}
                />
                <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={<RestartAltIcon />}
                  onClick={resetButton}
                  className="button_style"
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  disabled={calculateDisabled}
                  onClick={() => calculateButton(radius)}
                  startIcon={<CalculateIcon />}
                  className="button_style"
                >
                  Calculate
                </Button>
                </Stack>
              </Stack> 
              <div className={`price-comparision-map ${isMaximize ? 'map-size-full' : 'map-size-mid'}`}>

                {storeDataState && (
                  <Map 
                    zoom={4}
                    center={{ lat: 41.124656, lng: -100.770216 }}
                    mapBounds={mapBounds}
                    style={{
                      height: "100%",
                      width: "100%",
                      overflow: "hidden",
                      borderRadius: "5px",
                    }}
                  >
                    {primaryLocationMarker && (
                      <Marker
                        optimized={true}
                        importcode={primaryLocationMarker?.importcode}
                        description={primaryLocationMarker?.description}
                        infowindow={primaryLocationMarker?.infowindow}
                        icon={primaryLocationMarker?.icon}
                        key="primary-location"
                        position={primaryLocationMarker?.latLng}
                      />
                    )}
                    {otherLocationsMarkers?.map((marker, i) => (
                      <Marker
                        optimized={true}
                        importcode={marker?.importcode}
                        description={marker?.description}
                        infowindow={marker?.infowindow}
                        icon={marker?.icon}
                        key={i}
                        position={marker?.latLng}
                      />
                    ))}
                  </Map>
                )}
              </div>
            </div>
          </>
        )}
      </Stack>
    </div >
  );
};

export default PriceComparison;
