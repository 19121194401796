import KpiDashboard from "./KpiDashboard";
import { KpiDashBoardMountProps } from "../types/KpiDashboardMountProps";

export default function App(singleSpaMountProps) {

    console.log("visops-tableau-kpi: customProps",singleSpaMountProps);

    return (
        <KpiDashboard customProps = {singleSpaMountProps.customProps}/>
    );
    
}