import React, {useEffect, useState} from "react";
import Slider from "@mui/material/Slider";
import "./range-selector.css";

const RangeSelector = (props) => {
  const [value, setValue] = useState(props.radius);

  const handleChange = (event: Event,
                        newValue: number | number[],
                        activeThumb: number) => {
    props.setRadius(newValue);
    props.setCalculateDisabled(false);
  };

  useEffect(() => {
    setValue(props.radius);
  }, [props.radius]);

  return (
      <div className="range-selector-container">
        Miles (0.5-5)
        <Slider
            aria-label="Radius"
            color='success'
            min={0.5}
            max={5}
            step={0.5}
            value={value}
            valueLabelDisplay="auto"
            onChange={handleChange}
            marks
        />
      </div>
  );
};
export default RangeSelector;
